// organize-imports-ignore
import { ToastContainer } from '@/components/toast/ToastContainer';
import { handleAPIError } from '@/utils/handleAPIError';
import type { AppProps } from 'next/app';
import { useCallback, useEffect } from 'react';
import '../styles/global.css';
import 'react-toastify/dist/ReactToastify.css';
import Metadata from '@/components/common/Metadata';
import { useRouter } from 'next/router';
import Analytics from '@/components/analytics/Analytics';
import { clearStorages, logout, useMember } from '@/hooks/member/Member.hooks';
import { initialize } from '@/api/axios';

declare global {
  interface Window {
    Kakao: any;
  }
}

export default function App({ Component, pageProps }: AppProps) {
  const { pathname, push } = useRouter();
  const isOgNeeded = pathname === '/product/[productId]' || pathname === '/story/[storyId]';

  const { error } = useMember({ shouldRetryOnError: false });

  const handleDeletedUser = useCallback(async () => {
    clearStorages();
    await logout();
    await push('/');
  }, [push]);

  useEffect(() => {
    if (error && error.response.data.code === 404) {
      handleDeletedUser();
    }
  }, [error, handleDeletedUser]);

  useEffect(() => {
    initialize();
    handleAPIError();
  }, []);

  return (
    <>
      <Analytics />
      <script
        type="text/javascript"
        src={`//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.NEXT_PUBLIC_MAP_KEY}&autoload=false&libraries=services`}
        async
      />
      {!isOgNeeded && <Metadata />}
      <Component {...pageProps} />
      <ToastContainer />
    </>
  );
}
